import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value?.password_set && to.path !== localePath("/set-password")) {
        console.warn("User has not yet set a password", to.path);
        return navigateTo(localePath("/set-password"));
    }
});
