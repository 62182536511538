<template>
    <div>
        <div
            :class="{
                'np-outer-switch': true,
                'np-outer-switch-on': model,
                'np-outer-switch-off': !model,
            }"
            :aria-disabled="disabled"
            @click="toggleSwitch"
        >
            <div
                :class="{
                    'np-inner-switch': true,
                    'np-inner-switch-left': !model,
                    'np-inner-switch-right': model,
                }"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
const model = defineModel<boolean | null>();

const props = withDefaults(
    defineProps<{
        disabled?: boolean;
    }>(),
    {
        disabled: false,
    },
);

const toggleSwitch = () => {
    if (props.disabled) {
        return;
    }

    model.value = !model.value;
};
</script>

<style scoped>
.np-outer-switch {
    @apply w-7 h-4 bg-gray-300 cursor-pointer relative rounded-2xl;
}

.np-outer-switch[aria-disabled="true"] {
    @apply opacity-50 cursor-not-allowed;
}

.np-outer-switch-off {
    @apply bg-gray-300 transition-colors duration-300;
}

.np-outer-switch-on {
    @apply bg-primary transition-colors duration-300;
}

.np-inner-switch {
    @apply w-3 h-3 bg-white rounded-full absolute top-[0.15rem] left-[0.15rem];
}

.np-inner-switch-left {
    @apply transition-all duration-300 top-[0.15rem] left-0.5;
}

.np-inner-switch-right {
    @apply transition-all duration-300 top-[0.15rem] left-[0.875rem];
}
</style>
