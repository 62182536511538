import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (user.value?.password_set) {
        console.warn("User has set a password but should not have", to.path);
        return navigateTo(localePath("/"));
    }
});
