import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value) {
        return;
    }

    if (!user.value.two_factor_confirmed) {
        console.warn("User did not confirm their 2fa, but should have been", to.path);
        return navigateTo(localePath("/two-factor"));
    }
});
