<template>
    <LayoutModal
        ref="cookieModal"
        size="sm:max-w-4xl text-left"
        :center="false"
        :closable="false"
    >
        <template #default>
            <h3
                class="mt-0 text-lg uppercase font-semibold text-left"
                v-text="$t('cookie-banner.title')"
            />

            <tab-group
                class="mt-8 max-w-auto"
                tab-content-classes="!max-w-full !w-full !mx-0"
                :tabs-bold="false"
                :tabs="[
                    {
                        id: 'info',
                        title: $t('cookie-banner.tab-info'),
                        selected: activeTab === 'info',
                    },
                    {
                        id: 'settings',
                        title: $t('cookie-banner.tab-settings'),
                        selected: activeTab === 'settings',
                    },
                    {
                        id: 'details',
                        title: $t('cookie-banner.tab-cookies'),
                        selected: activeTab === 'details',
                    },
                ]"
                @change-tab="changeTab"
            >
                <template v-if="activeTab === 'info'">
                    <i18n-t
                        tag="p"
                        keypath="cookie-banner.description"
                    >
                        <template #link>
                            <nuxt-link
                                :to="localePath('/cms/privacy-policy')"
                                class="underline"
                            >
                                {{ $t("cookie-banner.description_link") }}
                            </nuxt-link>
                        </template>
                    </i18n-t>
                </template>
                <template v-if="activeTab === 'settings'">
                    <div class="w-full flex flex-col gap-y-2">
                        <div class="bg-beige px-6 py-5 flex items-center">
                            <form-toggle
                                :model-value="cookieStore.isEverythingEnabled"
                                @update:model-value="toggleAll($event)"
                            />

                            <p class="text-sm ml-4 uppercase font-semibold">
                                {{ $t("cookie-banner.settings.select-all") }}
                            </p>
                        </div>

                        <cookie-settings
                            :title="$t('cookie-banner.settings.essentials.title')"
                            :description="$t('cookie-banner.settings.essentials.description')"
                            :cookies="cookies.necessary"
                            :necessary="true"
                        />

                        <cookie-settings
                            :title="$t('cookie-banner.settings.statistics.title')"
                            :description="$t('cookie-banner.settings.statistics.description')"
                            :cookies="cookies.statistic"
                        />

                        <cookie-settings
                            :title="$t('cookie-banner.settings.marketing.title')"
                            :description="$t('cookie-banner.settings.marketing.description')"
                            :cookies="cookies.marketing"
                        />
                    </div>
                </template>
                <template v-else-if="activeTab === 'details'">
                    <cookie-list :cookies="cookies" />
                </template>
            </tab-group>

            <p class="mt-10 text-sm">
                <nuxt-link
                    :to="localePath('/cms/privacy-policy')"
                    class="underline"
                    >{{ $t("cookie-banner.privacy-policy") }}</nuxt-link
                >

                <nuxt-link
                    :to="localePath('/cms/imprint')"
                    class="underline ml-16"
                    >{{ $t("cookie-banner.imprint") }}</nuxt-link
                >
            </p>

            <div class="flex flex-col md:flex-row gap-4 md:gap-8 mt-10">
                <form-button
                    type="primary"
                    :classes="['sm:w-80 flex-1']"
                    @click="denyOptionalCookies()"
                >
                    {{ $t("cookie-banner.deny") }}
                </form-button>
                <form-button
                    type="primary"
                    :classes="['sm:w-80 flex-1']"
                    @click="adjustOrSaveCookies()"
                >
                    <template v-if="activeTab === 'settings'">
                        {{ $t("cookie-banner.save-settings") }}
                    </template>
                    <template v-else>
                        {{ $t("cookie-banner.show-settings") }}
                    </template>

                    <icon-arrow-right class="h-6 w-auto ml-2" />
                </form-button>

                <form-button
                    type="primary"
                    :classes="['sm:w-80 flex-1']"
                    @click="acceptAllCookies()"
                >
                    {{ $t("cookie-banner.accept-all") }}
                </form-button>
            </div>
        </template>
    </LayoutModal>
</template>
<script lang="ts" setup>
import IconArrowRight from "~/public/icons/arrow_right.svg";
import type { Cookie } from "~/types";
import type { LayoutModal } from "#components";

const { t } = useI18n();
const route = useRoute();
const cookieStore = useCookieStore();
const { isSet } = storeToRefs(cookieStore);
const { $listen } = useNuxtApp();
const localePath = useLocalePath();

const cookies = ref<{
    necessary: Cookie[];
    statistic: Cookie[];
    marketing: Cookie[];
}>({
    necessary: [
        {
            id: "i18n_redirected",
            title: t("cookie-banner.cookies.i18n_redirected.title"),
            purpose: t("cookie-banner.cookies.i18n_redirected.purpose"),
            provider: t("cookie-banner.cookies.i18n_redirected.provider"),
            names: ["i18n_redirected"],
            expires: t("cookie-banner.cookies.i18n_redirected.expires"),
            checked: true,
            privacy_policy: localePath("/cms/privacy-policy"),
        },
        {
            id: "kadewe_loyalty_staging_session",
            title: t("cookie-banner.cookies.kadewe_loyalty_staging_session.title"),
            purpose: t("cookie-banner.cookies.kadewe_loyalty_staging_session.purpose"),
            provider: t("cookie-banner.cookies.kadewe_loyalty_staging_session.provider"),
            names: ["kadewe_loyalty_staging_session"],
            expires: t("cookie-banner.cookies.kadewe_loyalty_staging_session.expires"),
            checked: true,
            privacy_policy: localePath("/cms/privacy-policy"),
        },
        {
            id: "xsrf-token",
            title: t("cookie-banner.cookies.xsrf-token.title"),
            purpose: t("cookie-banner.cookies.xsrf-token.purpose"),
            provider: t("cookie-banner.cookies.xsrf-token.provider"),
            names: ["xsrf-token"],
            expires: t("cookie-banner.cookies.xsrf-token.expires"),
            checked: true,
            privacy_policy: localePath("/cms/privacy-policy"),
        },
    ],
    statistic: [
        {
            id: "google_analytics",
            title: t("cookie-banner.cookies.google_analytics.title"),
            purpose: t("cookie-banner.cookies.google_analytics.purpose"),
            provider: t("cookie-banner.cookies.google_analytics.provider"),
            names: ["_ga", "_gat", "_gid"],
            expires: t("cookie-banner.cookies.google_analytics.expires"),
            checked: true,
            privacy_policy: "https://policies.google.com/technologies/partner-sites?hl=de",
        },
    ],
    marketing: [
        {
            id: "meta_pixel",
            title: t("cookie-banner.cookies.meta_pixel.title"),
            purpose: t("cookie-banner.cookies.meta_pixel.purpose"),
            provider: t("cookie-banner.cookies.meta_pixel.provider"),
            names: ["_fbp"],
            expires: t("cookie-banner.cookies.meta_pixel.expires"),
            checked: true,
            privacy_policy: "https://www.facebook.com/privacy/policy/",
        },
        {
            id: "braze_sdk",
            title: t("cookie-banner.cookies.braze_sdk.title"),
            purpose: t("cookie-banner.cookies.braze_sdk.purpose"),
            provider: t("cookie-banner.cookies.braze_sdk.provider"),
            names: ["ab.storage.userId", "ab.storage.sessionId", "ab.storage.deviceId", "ab._gd"],
            expires: t("cookie-banner.cookies.braze_sdk.expires"),
            checked: true,
            privacy_policy: "https://www.braze.com/privacy/",
        },
    ],
});

const cookieModal = ref<InstanceType<typeof LayoutModal> | null>(null);
const onPrivacyPolicy = ref(false);
const activeTab = ref<"info" | "settings" | "details">("info");

const updatePrivacyPolicyStatus = () => {
    if (route.params && (route.params.slug === "privacy-policy" || route.params.slug === "imprint")) {
        onPrivacyPolicy.value = true;
        return;
    }

    onPrivacyPolicy.value = false;
};

watch(
    () => route.params,
    () => {
        updatePrivacyPolicyStatus();

        if (onPrivacyPolicy.value) {
            cookieModal.value?.close();
        }
    },
);

watch(onPrivacyPolicy, async () => {
    if (!onPrivacyPolicy.value && !isSet.value) {
        cookieModal.value?.open();
    }
});

onMounted(() => {
    updatePrivacyPolicyStatus();

    if (!onPrivacyPolicy.value && !isSet.value) {
        cookieModal.value?.open();
    }

    $listen("cookie-banner:open", () => {
        cookieModal.value?.open();
    });
});

const close = () => {
    cookieModal.value?.close();
    activeTab.value = "info";
};

const saveCookies = () => {
    cookieStore.saveCookies();
    close();
};

const denyOptionalCookies = () => {
    cookieStore.denyOptionalCookies();
    close();
};

const adjustOrSaveCookies = () => {
    if (activeTab.value === "settings") {
        saveCookies();
        close();
        return;
    }

    changeTab({ tab: "settings" });
};

const acceptAllCookies = () => {
    cookieStore.acceptAllCookies();
    close();
};

const toggleAll = (checked: boolean) => {
    if (checked) {
        cookieStore.acceptAllCookies();
    } else {
        cookieStore.denyOptionalCookies();
    }
};

const changeTab = ({ tab }: { tab: "info" | "settings" | "details" }) => {
    activeTab.value = tab;
};
</script>
