import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value) {
        return;
    }

    if (user.value.two_factor_confirmed) {
        console.warn("User confirmed their 2fa, but should not have been", to.path);
        return navigateTo(localePath("/portal/card"));
    }
});
