import * as braze from "@braze/web-sdk";

export default defineNuxtPlugin(() => {
    const {
        public: {
            braze: { api_key: apiKey, base_url: baseUrl },
        },
    } = useRuntimeConfig();
    const user = useSanctumUser<KadeweUser>();

    return {
        provide: {
            braze: {
                initialize: () => {
                    if (!apiKey) {
                        console.warn("Braze API key is not set");
                        return;
                    }

                    braze.initialize(apiKey, {
                        baseUrl: baseUrl,
                        enableLogging: true,
                        openCardsInNewTab: true,
                    });

                    if (user.value && user.value.request_id) {
                        braze.changeUser(user.value.request_id);
                    }

                    braze.openSession();
                },
            },
        },
    };
});
