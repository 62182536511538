import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value?.parking || user.value?.card_status === "royal") {
        console.warn("User has no access to the parking page, trying to access", to.path);
        return navigateTo(localePath("/portal/card"));
    }
});
