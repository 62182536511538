import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value?.email_verified_at && to.path !== localePath("/verify")) {
        console.warn("User is not verified but should be", to.path);
        return navigateTo(localePath("/verify"));
    }
});
