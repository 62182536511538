import type { CookieName } from "~/types";

const COOKIE_VERSION = 2;

export const useCookieStore = defineStore("cookies", () => {
    const { gtag, initialize } = useGtag();
    const { $fb, $braze } = useNuxtApp();

    const necessaryCookies = ["i18n_redirected", "kadewe_loyalty_staging_session", "xsrf-token"];
    const isSet = ref(false);

    const cookieStatus = ref({
        i18n_redirected: true,
        kadewe_loyalty_staging_session: true,
        "xsrf-token": true,
        google_analytics: null,
        meta_pixel: null,
        braze_sdk: null,
        version: 1,
    });

    const isEverythingEnabled = computed(() => {
        // Check if every CookieName in the cookieStatus object is true, do not check the version
        for (const cookieName in cookieStatus.value) {
            if (cookieName === "version") {
                continue;
            }

            if (cookieStatus.value[cookieName as CookieName] !== true) {
                console.warn(`Cookie ${cookieName} is not enabled`);
                return false;
            }
        }

        return true;
    });

    const isGoogleAnalyticsEnabled = computed(() => {
        return cookieStatus.value.google_analytics === true;
    });

    const isMetaPixelEnabled = computed(() => {
        return cookieStatus.value.meta_pixel === true;
    });

    const isBrazeSdkEnabled = computed(() => {
        return cookieStatus.value.braze_sdk === true;
    });

    const loadCookies = () => {
        // Load the cookie state from localStorage and return if they were already set in the current version
        const cookieState = localStorage.getItem("cookies");
        if (cookieState !== null) {
            Object.assign(cookieStatus.value, JSON.parse(cookieState));

            if (COOKIE_VERSION === getCookieVersion()) {
                console.debug("Cookies loaded from localStorage and are up to date");
                return true;
            } else {
                console.debug("Cookies loaded from localStorage but are outdated");
                return false;
            }
        }

        console.debug("No cookies found in localStorage");
        return false;
    };

    const updateCookie = (cookieName: CookieName, value: boolean): boolean => {
        if (necessaryCookies.includes(cookieName)) {
            console.warn(`Cannot change the status of necessary cookie ${cookieName}`);
            return false;
        }

        // Update the cookie state but don't save it to localStorage yet
        cookieStatus.value[cookieName] = value;
        return true;
    };

    const saveCookies = () => {
        cookieStatus.value.version = COOKIE_VERSION;
        localStorage.setItem("cookies", JSON.stringify(cookieStatus.value));
        isSet.value = true;

        initScripts();
    };

    const denyOptionalCookies = () => {
        for (const cookieName in cookieStatus.value) {
            if (necessaryCookies.includes(cookieName as CookieName)) {
                continue;
            }

            updateCookie(cookieName as CookieName, false);
        }

        saveCookies();
    };

    const acceptAllCookies = () => {
        for (const cookieName in cookieStatus.value) {
            updateCookie(cookieName as CookieName, true);
        }

        saveCookies();
    };

    const initScripts = () => {
        // Handle Google Analytics
        if (isGoogleAnalyticsEnabled) {
            console.debug("Initializing google analytics");

            initialize();

            gtag("consent", "update", {
                analytics_storage: "granted",
            });
        } else {
            gtag("consent", "update", {
                ad_storage: "denied",
                analytics_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
            });
        }

        // Handle meta pixel
        if (isMetaPixelEnabled) {
            console.debug("Initializing meta pixel");

            $fb.enable();
        } else {
            $fb.disable();
        }

        if (isBrazeSdkEnabled) {
            $braze.initialize();
        }
    };

    const trackEvent = (name: string, payload: object | undefined = undefined) => {
        try {
            if (isGoogleAnalyticsEnabled) {
                gtag("event", name, payload);
            }

            if (isMetaPixelEnabled) {
                // @ts-expect-error - Meta pixel is not yet typed
                nuxtApp.$fb.trackCustom(name, payload);
            }
        } catch (error) {
            console.error("Failed to track event", error);
        }
    };

    const getCookieVersion = () => {
        return cookieStatus.value.version || 1;
    };

    isSet.value = loadCookies();
    initScripts();

    return {
        cookieStatus,
        isSet,
        isEverythingEnabled,
        isGoogleAnalyticsEnabled,
        isMetaPixelEnabled,
        updateCookie,
        saveCookies,
        denyOptionalCookies,
        acceptAllCookies,
        trackEvent,
    };
});
