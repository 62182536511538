import type { KadeweUser } from "~/types";

export default defineNuxtRouteMiddleware((to) => {
    const user = useSanctumUser<KadeweUser>();
    const localePath = useLocalePath();

    if (!user.value) {
        return;
    }

    if (user.value.email_verified_at !== null) {
        console.warn("User is verified, but should not be", to.path);
        return navigateTo(localePath("/portal/card"));
    }
});
